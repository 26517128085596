// src/components/screens/ClubList.tsx
import React, { useState, useEffect } from 'react';
import { TextField, List, ListItem, ListItemText, Container, Typography, Box, Button, Grid, Link } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import Logo from '../../../assets/images/logo.png';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';

const theme = createTheme({
  palette: {
      primary: {
      main: '#000000',
  },
    },
});

type Club = {
  _id: string;
  name: string;
};



const ClubSelect = () => {
  const [clubs, setClubs] = useState<Club[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedClubId, setSelectedClubId] = useState<string | null>(null);
  const sourceClub = localStorage.getItem('sourceClub');
  const navigate = useNavigate();
  


  useEffect(() => {
    fetchClubs();
  }, []);

  let filteredClubs;

  const fetchClubs = async () => {
   
    try {
      const response = await axios.get('https://minerva.sipora.io/api/clubs/retrieve'); // Replace with your actual API endpoint
      setClubs(response.data.clubs); // Adjust based on your API response structure
    } catch (error) {
      console.error('Error fetching clubs:', error);
    }
   
  };
  
    if (!sourceClub) {
    filteredClubs = clubs.filter(club =>
    club.name.toLowerCase().includes(searchTerm.toLowerCase())
  ); } else {
    // Need to look up the sourceClub short code with the club
    filteredClubs = clubs.filter(club =>
    club.name.toLowerCase().includes(sourceClub.toLowerCase())
  ); }


  const handleClubSelect = (clubId: string) => {
    setSelectedClubId(clubId);
  };

  const handleContinue = () => {
      if (selectedClubId) {
        navigate('/register', { state: {selectedClubId }});
      }
    };

  return (
    <Container component="main" maxWidth="xs">
 
<Box display="flex" flexDirection="column" alignItems="center" mt={8} p={2} >
      <Grid container justifyContent="center" mb={2}>
      
      <Grid item xs={12} justifyContent="center">
      <Box display="flex" justifyContent="center">
      <img src={Logo} alt="Logo"  style={{ width: '70%', height: 'auto'}} />
      </Box></Grid></Grid>
          

      <Typography variant="h6" component="h2" align="center" gutterBottom>
        Choose an organisation to support 
      </Typography>
      <Box display="flex" alignItems="center" mb={2} style={{ width: '100%'}}>
        
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          placeholder="Search for a club here..."
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '16px', // Apply border radius
            },
        }}
          InputProps={{
            startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
          }}
          onChange={e => setSearchTerm(e.target.value)}
        
        />
      </Box>
      <Box style={{ maxHeight: '300px', overflowY: 'auto' }}>
        <List>
          {filteredClubs.map(club => (
            <ListItem key={club._id} button
            onClick={() => handleClubSelect(club._id)}
            selected={selectedClubId === club._id}
          >
              <ListItemText primary={club.name} />
            </ListItem>
          ))}
        </List>
      </Box>
     
      <ThemeProvider theme={theme}>
   
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: "100%"  }}
            onClick={handleContinue}
            disabled={!selectedClubId}
           
          >
            CONTINUE
         
          </Button>
 </ThemeProvider>

 <Grid item xs={12} justifyContent="center">
          <Box display="flex" justifyContent="center" p={1}>
          <Typography variant="body2"> Already have an account? <Link component={RouterLink} to="/" variant="body2" sx={{ ml: 0.5, textDecoration: 'none' }}> Login
            </Link>
            </Typography></Box>
            </Grid>
                    
          <Box mt={2} display="flex" justifyContent="center">
            <Link href="#" variant="body2" sx={{ mr: 3}}>
              Help
            </Link>
            <Link href="#" variant="body2" >
              Terms
            </Link>
            <Link href="#" variant="body2" sx={{ ml: 3}}>
              Privacy
            </Link>
          </Box>
        </Box>
    </Container>
  );
};

export default ClubSelect;

export {};