
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyANhyek7FAyh2bU1Wv1VDaUuH1X_8zkEXU",
  authDomain: "rounda-dev.firebaseapp.com",
  projectId: "rounda-dev",
  storageBucket: "rounda-dev.appspot.com",
  messagingSenderId: "25355700885",
  appId: "1:25355700885:web:a6f64a0b795ea87469699c",
  measurementId: "G-ZY2XDXF3SB"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
auth.useDeviceLanguage();

export { auth };