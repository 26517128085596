import React, { useState, useEffect } from 'react';
import { FormControlLabel, Checkbox, Typography, Box, Button, Grid, Link, Stepper, Step, StepLabel, Container } from '@mui/material';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    background: {
      default: '#47b3b5', // Set your desired background colour here
    },
    appbar: {
      default: '#ffffff', // Set your desired background colour here
    },
    switchColor: {
      main: '#47b3b5',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#ffffff', // Ensure the body has the same background colour
        },
        secondary: {
          backgroundColor: '#47b3b5', // Ensure the body has the same background colour
        },
        switch: {
          main: '#47b3b5',
        }
      },
    },
  },
});

const steps = [
  'Register Account',
  'Connect To Bank',
  'Select Round-Up Accounts',
  'Select Funding Account'
];

const FundingAccounts = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [stepperStep, setStepperStep] = useState(2);
  const { accounts } = location.state || {};
  const cdrid = sessionStorage.getItem('cdrid');
  

  useEffect(() => {
    if (!accounts || accounts.length === 0) {
      // Redirect to a different page or show an error message
      navigate('/previous-page'); // Adjust this to your needs
    }
  }, [accounts, navigate]);

  const handleCheckboxChange = (accountId) => {
    setSelectedAccount(accountId);
  };
  

  const handleSubmitFundingAccount = async () => {
    setIsLoading(true);
          
    try {
      await axios.post('https://minerva.sipora.io/api/client/round-up/user/register-funding', {
        accountId: selectedAccount, tenant: 'ROUNDA-DEV', activeDebitEnabled: true, cdrid: cdrid
      });
      navigate('/finished'); // Adjust the navigation path as needed
    } catch (error) {
      console.error('Error submitting selected accounts:', error);
      setErrorMessage('There seems to be an error, please try again or contact support')
    } finally {
      setIsLoading(false);
    }
  };

  if (!accounts || accounts.length === 0) {
    return (
      <Container component="main" maxWidth="xs">
        <Box display="flex" flexDirection="column" alignItems="center" mt={4} p={2}>
          <Typography variant="h6" component="h2" align="center" mb={2}>
            No accounts available. Please go back and select accounts.
          </Typography>
          <Button
            onClick={() => navigate('/previous-page')} // Adjust this to your needs
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: '100%' }}
          >
            <Typography>Go Back</Typography>
          </Button>
        </Box>
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box display="flex" flexDirection="column" alignItems="center" mt={4} p={2}>
        <Grid container justifyContent="center" mb={2}>
          <Box display="flex" flexDirection="column" alignItems="center" mt={4} mb={6} style={{ width: '100%' }}>
            <Stepper activeStep={stepperStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Typography variant="h4" component="h2" align="center" mb={2} sx={{ fontWeight: 'bold' }} gutterBottom>
            Funding Account
          </Typography>
          <Box display="flex" flexDirection="column" alignItems="left" bgcolor="white" mt={1} p={2} borderRadius={4}>
            <Typography variant="body2" align="left" gutterBottom>
              The selected account will have round-up totals debited every Monday, Wednesday, and Friday.
            </Typography>
            <Typography variant="body2" align="left" gutterBottom>
              By selecting a funding account, you are agreeing to Zepto's terms and conditions which can be found here:
            </Typography>
            <Box p={1}>
              {accounts.map((account, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                    checked={selectedAccount === account.id}
                      onChange={(event) => handleCheckboxChange(account.id)}
                    />
                  }
                  label={
                    <Box>
                      <Typography variant="body2">{account.name} {account.accountNumber}</Typography>
                    </Box>
                  }
                />
              ))}
            </Box>
          </Box>
        </Grid>
        <ThemeProvider theme={theme}>
          <Button
            onClick={handleSubmitFundingAccount}
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: '100%' }}
            disabled={isLoading}
          >
            <Typography>
              {isLoading ? 'Loading...' : 'CONTINUE'}
            </Typography>
          </Button>
        </ThemeProvider>
        <Box mt={2} display="flex" justifyContent="center">
          <Link href="#" variant="body2" sx={{ mr: 3 }}>
            Help
          </Link>
          <Link href="#" variant="body2">
            Terms
          </Link>
          <Link href="#" variant="body2" sx={{ ml: 3 }}>
            Privacy
          </Link>
        </Box>
      </Box>
    </Container>
  );
};

export default FundingAccounts;
