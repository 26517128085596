import React, { useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const LogoutPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const logOutUser = async () => {
      try {
        const auth = getAuth();
        await signOut(auth);
        // Clear any user-related data if necessary, e.g., from localStorage
        sessionStorage.removeItem('userId');
        // Redirect to login page
        navigate('/');
      } catch (error) {
        console.error('Sign out error', error);
      }
    };

    logOutUser(); // Call the logout function here
  }, [navigate]);

  return (
    <div>
      Logging out...
    </div>
  );
};

export default LogoutPage;
