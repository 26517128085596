// src/components/screens/ClubList.tsx
import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Button, Grid, FormControlLabel, CssBaseline, Toolbar, AppBar, Checkbox, Modal, TextField  } from '@mui/material';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import DrawerMenu from '../Shared/DrawerMenu';
import { useFormik } from 'formik';
import * as yup from 'yup';

const theme = createTheme({
    palette: {
      primary: {
        main: '#000000',
      },
      background: {
        default: '#47b3b5', // Set your desired background colour here
      },
      appbar: {
        default: '#ffffff', // Set your desired background colour here
      },
      switchColor: {
        main: '#47b3b5',
      },
      deleteButton: {
        main: '#ff0000',
      }
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: '#ffffff', // Ensure the body has the same background colour
          },
          secondary: {
            backgroundColor: '#47b3b5', // Ensure the body has the same background colour
          },
          switch: {
            main: '#47b3b5',
          }
        },
      },
    },
  });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 6,
    p: 4,
  };
  

  const validationSchema = yup.object({
    accountName: yup.string().required('Account name is required'),
    bsb: yup.string().matches(/^\d{6}$/, 'BSB must be 6 numbers').required('BSB is required'),
    accountNumber: yup.string().matches(/^\d{8,12}$/, 'Account number must be 8-12 numbers').required('Account number is required'),
     });

const LinkedAccounts = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingConsent, setIsLoadingConsent] = useState(false);
    const [isLoadingSavings, setIsLoadingSavings] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const cdrid = sessionStorage.getItem('cdrid');
    const userId = sessionStorage.getItem('userId');
    const [accounts, setAccounts] = useState([]);
    const [selectedDebitAccount, setSelectedDebitAccount] = useState('');
    const [fundingAccountSetError, setFundingAccountSetError] = useState('');
    const [savingsAccountValue, setSavingsAccountValue] = useState('');
    const [savingsAccountName, setSavingsAccountName] = useState('');
    const [savingsAccountMissing, setSavingsAccountMissing] = useState('');
    const [manageConsentLink, setManageConsentLink] = useState('');
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
   
    const formik = useFormik({
      initialValues: {
        accountName: '',
        bsb: '',
        accountNumber: '',
      },
      validationSchema: validationSchema,
         onSubmit: async (values) => {
         setIsLoading(true);
        try {
        
          const response = await axios.post('https://minerva.sipora.io/api/client/round-up/user/submit-savings-account', {cdrid, values},
            { headers: {'Content-Type': 'application/json'} }
          );
          await axios.post(
            'https://minerva.sipora.io/api/client/round-up/user/linked-savings',
            { userId: cdrid },
            { headers: { 'Content-Type': 'application/json' } }
          );
          if (!response.document) {
            setSavingsAccountName('you have not linked a savings account yet');
          } else {
            
          const accountName = response.data.savingsAccount.document.accountName;
          const account = response.data.savingsAccount.document.accountNumber;
            const lastFourDigits = account.slice(-4);
            const maskedSection = account.slice(0, -4).replace(/\d/g, 'X');
            const displayAccount = maskedSection + lastFourDigits;
          setSavingsAccountValue(displayAccount);
          setSavingsAccountName(accountName);
          console.log(displayAccount);
          setOpen(false);
          navigate('/linked-accounts')

        } } catch (error) {
          console.error('Error registering user:', error);
          setMessage('Failed to register. Please try again.');
        }
      },
    });


    
   
  useEffect(() => {
      fetchAccounts();
    }, []);
  
    const fetchAccounts = async () => {
      try {
        const response = await axios.post('https://minerva.sipora.io/api/client/round-up/user/accounts',
          {userId: cdrid},
          { headers: { 'Content-Type': 'application/json' } }
          ); 
       setAccounts(response.data.accountsData.documents); // Adjust based on your API response structure
      } catch (error) {
        console.error('Error fetching clubs:', error);
      }
    };
  
    const handleDebitAccountChange = async (_id) => {
         try {
        const response = await axios.post('',
        {accountId: selectedDebitAccount},
          { headers: { 'Content-Type': 'application/json' } }
        );
        setSelectedDebitAccount(_id);
      } catch (error) {
        setFundingAccountSetError('There seems to be a problem with changing your funding account. Please try again later or contact support.')
        console.error('Error fetching clubs:', error);
      }
    };
  

    useEffect(() => {
      const getSavingsAccount = async () => {
        try {
          const response = await axios.post(
            'https://minerva.sipora.io/api/client/round-up/user/linked-savings',
            { userId: cdrid },
            { headers: { 'Content-Type': 'application/json' } }
          );
          if (!response) {
            setSavingsAccountMissing('no account linked')
          } else {
          const accountName = response.data.savingsAccount.document.accountName;
          const account = response.data.savingsAccount.document.accountNumber;
            const lastFourDigits = account.slice(-4);
            const maskedSection = account.slice(0, -4).replace(/\d/g, 'X');
            const displayAccount = maskedSection + lastFourDigits;
          setSavingsAccountValue(displayAccount);
          setSavingsAccountName(accountName);
          console.log(displayAccount);
        } } catch (error) {
          console.error('failed to retrieve linkedSavings', error);
          setMessage('failed to retrieve linkedSavings.');
        }
      };
  
      if (cdrid) {
        getSavingsAccount();
      }
    }, [cdrid]);
   
   
    const handleManageConsent = async () => {
      setIsLoadingConsent(true);
      try {
        const response = await axios.post('https://minerva.sipora.io/api/client/round-up/user/manage-consent',
        {cdrid: cdrid},
        { headers: {'Content-Type': 'application/json'} }); // Replace with your actual API endpoint
        const consentLink = response.data.consentLink;
        setManageConsentLink(consentLink);
        window.location.href = consentLink // Adjust based on your API response structure
      } catch (error) {
        console.error('Error creating consent Link:', error);
      } finally {
        setIsLoadingConsent(false)
      }
    };


   

return (
    <ThemeProvider theme={theme}>
    <CssBaseline>
    <Container component="primary" maxWidth="xs"> 
    <AppBar position="static" color="appbar" elevation={0}>
          <Toolbar>
            <DrawerMenu />
            <Typography variant="body" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
              Linked Accounts
            </Typography>
          </Toolbar>
        </AppBar></Container>
        <Container component="secondary" maxWidth="xs"> 

  
        <Grid item xs={12} justifyContent="center" height={3}>
    </Grid>

    <Box display="flex" flexDirection="column" alignItems="left" bgcolor="white" mt={2} p={2} borderRadius={4} >
    <Typography variant="body"  align="left" justifyContent="left" sx={{ flexGrow: 1, fontWeight: 'bold' }} gutterBottom >
         Round-Up Accounts  
        </Typography>
        <Typography variant="body2"  align="left" justifyContent="left" gutterBottom >
         Transactions will be tracked from the accounts selected below.  
        </Typography>
        <Box p={1}>
         {accounts.map((account, index) => (
           <FormControlLabel
             key={index}
             control={<Checkbox />}
             label={
              <Box>
               <Typography variant="body2">{account.name}  {account.accountNumber}</Typography>
            </Box>
             }
            />
          ))}
          </Box>
     </Box>

     <Box display="flex" flexDirection="column" alignItems="left" bgcolor="white" mt={2} p={2} borderRadius={4} >
    <Typography variant="body"  align="left" justifyContent="left" sx={{ flexGrow: 1, fontWeight: 'bold' }} gutterBottom >
         Funding Accounts  
        </Typography>
        <Typography variant="body2"  align="left" justifyContent="left" gutterBottom >
         The selected account will have round-up totals debited every from ever Monday , Wednesday, and Friday.
        </Typography>
        <Typography variant="body2"  align="left" justifyContent="left" gutterBottom >
        By selecting a funding account, you are agreeing to Zepto's terms and conditions which can be found here:
        </Typography>

        <Box p={1}>
         {accounts.map((account, index) => (
           <FormControlLabel
             key={index}
             control={
             <Checkbox
             checked={selectedDebitAccount === account._id}
             onChange={() => handleDebitAccountChange(account._id)} />}
             label={
              <Box>
               <Typography variant="body2">{account.name}  {account.accountNumber}</Typography>
            </Box>
             }
            />
          ))}
          </Box>
          <Typography variant="caption" color="red" align="left" justifyContent="left" gutterBottom >{fundingAccountSetError}</Typography>
 
     </Box>

     <Box display="flex" flexDirection="column" alignItems="left" bgcolor="white" mt={2} p={2} borderRadius={4} >
    <Typography variant="body"  align="left" justifyContent="left" sx={{ flexGrow: 1, fontWeight: 'bold' }} gutterBottom >
         Linked Savings Account  
        </Typography>
     <Box p={1}>
      {savingsAccountMissing}
        <Box display="flex" alignItems="center" justifyContent="center" width="100%" mt='10px'>
                                  {savingsAccountName}
                  </Box>

            <Box display="flex" alignItems="center" justifyContent="center" width="100%" mt='10px'>
                                  {savingsAccountValue}
                  </Box>
          </Box>
          <Button
            onClick={handleOpen}
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: '100%' }}
            >
            Change Account
         
          </Button>
          <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{fontWeight: 'bold'}}>
           Savings Account
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
            Link a savings account for 50% of round-ups deposited monthly
          </Typography>
          <form onSubmit={formik.handleSubmit}>
          <TextField
              fullWidth
              id="accountName"
              name="accountName"
              label="Account Name"
              value={formik.values.accountName}
              onChange={formik.handleChange}
              error={formik.touched.accountName && Boolean(formik.errors.accountName)}
              helperText={formik.touched.accountName && formik.errors.accountName}
              required
              variant="outlined"
              sx={{ '& .MuiOutlinedInput-root': {borderRadius: '16px',  height: '45px', mt: '3px', mb: '3px'}, }}            />
           <Box display="flex" width="100%" justifyContent="space-between">
           <Box display="flex"  width="30%" >
            <TextField
              fullWidth
              id="bsb"
              name="bsb"
              label="BSB"
              value={formik.values.bsb}
              onChange={formik.handleChange}
              error={formik.touched.bsb && Boolean(formik.errors.bsb)}
              helperText={formik.touched.bsb && formik.errors.bsb}
              required
              variant="outlined"
              sx={{ '& .MuiOutlinedInput-root': {borderRadius: '16px',  height: '45px', mt: '3px', mb: '3px'}, }}            />
          </Box>
          <Box display="flex" width="69%" >
            <TextField
              fullWidth
              id="accountNumber"
              name="accountNumber"
              label="Account Number"
              value={formik.values.accountNumber}
              onChange={formik.handleChange}
              error={formik.touched.accountNumber && Boolean(formik.errors.accountNumber)}
              helperText={formik.touched.accountNumber && formik.errors.accountNumber}
              required
              variant="outlined"
              sx={{ '& .MuiOutlinedInput-root': {borderRadius: '16px',  height: '45px', mt: '3px', mb: '3px'}, }}            />
          </Box></Box>
          <Box display="flex" width="100%" justifyContent="space-between">
           <Box display="flex"  width="49%" >
           <Button onClick={handleClose}
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: '100%' }}
             >
            <Typography >
           Cancel
            </Typography>
         
          </Button>

           </Box>
           <Box display="flex"  width="49%" >
           <Button
            type="submit"
            fullWidth
            variant="contained"
            color="switchColor"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: '100%' }}
            disabled={isLoadingSavings}
          >
            <Typography >
            {isLoadingSavings ? 'Loading...' : 'Submit'}
            </Typography>
         
          </Button>

           </Box>
           </Box>

          </form>

        </Box>
        </Modal>
     </Box>
     <Button onClick={handleManageConsent}
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: '100%' }}
            disabled={isLoadingConsent}
          >
            <Typography >
            {isLoadingConsent ? 'Loading...' : 'Manage Bank Connection'}
            </Typography>
         
          </Button>
     <Grid item xs={12} justifyContent="center" height={30}>
    </Grid>

   

    </Container>
            </CssBaseline>
            </ThemeProvider>
       
  );

}
export default LinkedAccounts;

export {};