// src/components/screens/ClubList.tsx
import React, { useState, useEffect } from 'react';
import { TextField, List, ListItem, ListItemText, Container, Typography, Box, Button, Grid, Link, FormControlLabel, FormControl, FormLabel, RadioGroup, Switch, Collapse, CssBaseline, Divider, Toolbar, AppBar, Radio, Checkbox, Modal  } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import Logo from '../../../assets/images/logo.png';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import DrawerMenu from '../Shared/DrawerMenu';
import { styled } from '@mui/material/styles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useFormik } from 'formik';
import * as yup from 'yup';

const StyledRadio = styled(Radio)(({ theme }) => ({
  '&.Mui-checked': {
    color: '#47b3b5', // Custom color for checked state
  },
  '& .MuiSvgIcon-root': {
    fontSize: 24, // Adjust the size to match checkbox size
  },
}));

const theme = createTheme({
    palette: {
      primary: {
        main: '#000000',
      },
      background: {
        default: '#47b3b5', // Set your desired background colour here
      },
      appbar: {
        default: '#ffffff', // Set your desired background colour here
      },
      switchColor: {
        main: '#47b3b5',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: '#ffffff', // Ensure the body has the same background colour
          },
          secondary: {
            backgroundColor: '#47b3b5', // Ensure the body has the same background colour
          },
          switch: {
            main: '#47b3b5',
          }
        },
      },
    },
  });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 6,
    p: 4,
  };
  
  const validationSchema = yup.object({
    amount: yup.number().required('Amount is required').max(250, 'Amount must be $250 or less'),
     });

const Dashboard = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingDonation, setIsLoadingDonation] = useState(false);
    const [message, setMessage] = useState('');
    const [roundUpMessage, setRoundUpMessage] = useState('');
    const navigate = useNavigate();
    const [cdrid, setCdrid] = useState('');
    const [clubId, setClubId] = useState('');
    const userId = sessionStorage.getItem('userId');
    const [roundUpsOn, setRoundUpsOn] = useState(true);
    const [linkedSavingsOn, setLinkedSavingsOn] = useState();
    const [weeklyLimitOn, setWeeklyLimitOn] = useState(false);
    const [weeklyLimitValue, setWeeklyLimitValue] = useState(0);
    const [newWeeklyLimitValue, setNewWeeklyLimitValue] = useState();
    const [roundUpValue, setRoundUpValue] = useState(100);
    const [newRoundUpValue, setNewRoundUpValue] = useState();
    const [supportedClub, setSupportedClub] = useState('');
    const [savingsAccountEnabled, setSavingsAccountEnabled] = useState(false);
    const [savingsAccountValue, setSavingsAccountValue] = useState('');
    const [SavingsAccountName, setSavingsAccountName] = useState('');
    const [roundUpLabel, setRoundUpLabel] = useState('');
    const [weeklyLimitLabel, setWeeklyLimitLabel] = useState('');
    const [linkedSavingsLabel, setLinkedSavingsLabel] = useState('');
    const [linkedSavingsExists, setLinkedSavingsExists] = useState(false);
    const [savingsAccountMissing, setSavingsAccountMissing] = useState('');
    const [totalDebitAmount, setTotalDebitAmount] = useState(0);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
 
    const formik = useFormik({
      initialValues: {
        accountName: '',
        bsb: '',
        accountNumber: '',
      },
      validationSchema: validationSchema,
         onSubmit: async (values) => {
         setIsLoadingDonation(true);
        try {
        
          const response = await axios.post('https://minerva.sipora.io/api/client/round-up/user/', {cdrid, values},
            { headers: {'Content-Type': 'application/json'} }
          );
          setOpen(false);
         
          } catch (error) {
          console.error('Error registering user:', error);
          setMessage('Failed to register. Please try again.');
        }
      },
    });

    useEffect(() => {
      setRoundUpLabel(roundUpsOn ? 'On' : 'Paused');
    }, [roundUpsOn]);

    useEffect(() => {
      setWeeklyLimitLabel (weeklyLimitOn ? 'On' : 'Off');
    }, [weeklyLimitOn]);

    useEffect(() => {
      setLinkedSavingsLabel(linkedSavingsOn ? 'On' : 'Off');
    }, [linkedSavingsOn]);

    const handleRoundUpsToggle = async () => { 
          setRoundUpsOn((prev) => !prev); 
     try {
        const response = await axios.post(
          'https://minerva.sipora.io/api/client/round-up/user/set-pause',
          { cdrid: cdrid, isUserPausedRoundUp: roundUpsOn }, // Send the correct value
          { headers: { 'Content-Type': 'application/json' } }
        );
      } catch (error) {
        setRoundUpMessage('Something went wrong, please try again');
      }
    };

  const handleLinkedSavingsToggle = async () =>  {
    setLinkedSavingsOn((prev) => !prev);
    let savingsAccountEnabledValue;
    if (linkedSavingsOn) {
      savingsAccountEnabledValue = false;
    } else {
      savingsAccountEnabledValue = true;
      };
      try {
        const response = await axios.post(
          'https://minerva.sipora.io/api/client/round-up/user/set-savings-account',
          { cdrid: cdrid, savingsAccountEnabled: savingsAccountEnabledValue }, // Send the correct value
          { headers: { 'Content-Type': 'application/json' } }
        );
      } catch (error) {
     //   setRoundUpMessage('Something went wrong, please try again');
      }
    };
    
  
 
    const handleWeeklyLimitToggle = async () => { 
      setWeeklyLimitOn((prev) => !prev);
      let weeklyLimitValueUpdate;
      if (weeklyLimitOn) {
        weeklyLimitValueUpdate = 0
      } else {
        if (weeklyLimitValue === 0) {
          setWeeklyLimitValue(1000);
          weeklyLimitValueUpdate = 1000;
        } else
          weeklyLimitValueUpdate = weeklyLimitValue
      };
    try {
         const response = await axios.post(
        'https://minerva.sipora.io/api/client/round-up/user/set-weekly-limit',
        { cdrid: cdrid, weeklyMaximumLimit: weeklyLimitValueUpdate }, // Send the correct value
        { headers: { 'Content-Type': 'application/json' } }
      );
    } catch (error) {
   //   setRoundUpMessage('Something went wrong, please try again');
    }
  };


  const handleRoundUpValueChange = async (event) => {
    const newRoundUpValue = parseInt(event.target.value, 10);
    setRoundUpValue(newRoundUpValue);
    try {
      const response = await axios.post(
        'https://minerva.sipora.io/api/client/round-up/user/set-roundup-amount',
        { roundupAmountCents: newRoundUpValue, cdrid: cdrid },
        { headers: { 'Content-Type': 'application/json' } }
      );
    } catch (error) {

    }
  };

  const handleWeeklyLimitChange = async (event) => {
    const newWeeklyLimitValue = parseInt(event.target.value, 10);
    setWeeklyLimitValue(newWeeklyLimitValue);
    try {
      const response = await axios.post(
        'https://minerva.sipora.io/api/client/round-up/user/set-weekly-limit',
        { weeklyMaximumLimit: newWeeklyLimitValue, cdrid: cdrid },
        { headers: { 'Content-Type': 'application/json' } }
      );
    } catch (error) {

    }
  };
  
  const goToLinkedAccounts = () => navigate('/linked-accounts');

  useEffect(() => {
    const getUserRecord = async () => {
      if (!userId) {
        navigate('/');
      }
      try {
        const response = await axios.post(
          'https://minerva.sipora.io/api/client/round-up/user/get-user',
          { userId: userId },
          { headers: { 'Content-Type': 'application/json' } }
        );
        const cdrid = response.data.userRecord.document._id;
        sessionStorage.setItem('cdrid', cdrid);
        if (response.data.userRecord.document.isUserPausedRoundUp || response.data.userRecord.document.roundupAmountCents === '0') {
          setRoundUpsOn(false);
          setRoundUpLabel('Paused');
        }
        if (response.data.userRecord.document.weeklyMaximumLimit && response.data.userRecord.document.weeklyMaximumLimit > '0') {
          setWeeklyLimitOn(true);
          setWeeklyLimitValue(response.data.userRecord.document.weeklyMaximumLimit);
        }
        if (response.data.userRecord.document.savingsAccountEnabled) {
          setLinkedSavingsOn(true);
           }
        setCdrid(cdrid);
        setClubId(response.data.userRecord.document.supportingClubId);
        setRoundUpValue(response.data.userRecord.document.roundupAmountCents);
      } catch (error) {
        setMessage('Failed to register. Please try again.');
      }
    };

    getUserRecord();
  }, [userId, navigate]);

  

  useEffect(() => {
    const getClubName = async () => {
      try {
        const response = await axios.get('https://minerva.sipora.io/api/clubs/retrieve');
        console.log('Clubs:', response.data);
        const clubs = response.data.clubs;
        const club = clubs.find((club) => club._id === clubId);
        if (club) {
          setSupportedClub(club.name);
        }
      } catch (error) {
        setMessage('Failed to find Clubs. Please try again.');
      }
    };

    if (clubId) {
      getClubName();
    }
  }, [clubId]);

  useEffect(() => {
    const getSavingsAccount = async () => {
      try {
        const response = await axios.post(
          'https://minerva.sipora.io/api/client/round-up/user/linked-savings',
          { userId: cdrid },
          { headers: { 'Content-Type': 'application/json' } }
        );
        if (!response) {
          setSavingsAccountMissing('no account linked')
        } else {
          setLinkedSavingsExists(true)
        const accountName = response.data.savingsAccount.document.accountName;
        const account = response.data.savingsAccount.document.accountNumber;
          const lastFourDigits = account.slice(-4);
          const maskedSection = account.slice(0, -4).replace(/\d/g, 'X');
          const displayAccount = maskedSection + lastFourDigits;
        setSavingsAccountValue(displayAccount);
        setSavingsAccountName(accountName);
        console.log(displayAccount);
      } } catch (error) {
        console.error('failed to retrieve linkedSavings', error);
        setMessage('failed to retrieve linkedSavings.');
      }
    };
        

    if (cdrid) {
      getSavingsAccount();
    }
  }, [cdrid]);


  useEffect(() => {
    const getTotalDebits = async () => {
      try {
        const response = await axios.post(
          'https://minerva.sipora.io/api/client/round-up/user/retrieve-total-debits',
          { cdrid },
          { headers: { 'Content-Type': 'application/json' } }
        );
        setTotalDebitAmount(response.data.totalDebits);
        console.log(response)
      }  catch (error) {
        console.error('failed to retrieve total payments', error);
      }
    };
        

    if (cdrid) {
      getTotalDebits();
    }
  }, [cdrid]);
 

return (
    <ThemeProvider theme={theme}>
    <CssBaseline>
    <Container component="primary" maxWidth="xs"> 
    <AppBar position="static" color="appbar" elevation={0}>
          <Toolbar>
            <DrawerMenu />
            <Typography variant="body" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
              Dashboard
            </Typography>
          </Toolbar>
        </AppBar></Container>
        <Container component="secondary" maxWidth="xs"> 

  
        <Grid item xs={12} justifyContent="center" height={3}>
    </Grid>
    <Box display="flex" flexDirection="column" alignItems="center" bgcolor="white" mt={2} p={2} borderRadius={4} >
        <Grid container justifyContent="center" mb={2}>
            <Grid item xs={12} justifyContent="center">
                <Box display="flex" justifyContent="center" >
                    <img src={Logo} alt="Logo"  style={{ width: '60%', height: 'auto'}} />
                </Box>
            </Grid>
        </Grid>
        <Typography variant="body"  align="center" sx={{ fontWeight: 'bold' }} gutterBottom >
           Supporting:
        </Typography>
        <Typography variant="h6" align="center" sx={{ }} gutterBottom>
            {supportedClub}
        </Typography>
        <Box display="flex" alignItems="center" width="100%">
              <Box flexGrow={1}>
                <Divider />
              </Box>
              <Typography variant="body" sx={{ fontWeight: 'bold', mx: 2 }} gutterBottom >Donated</Typography>
              <Box flexGrow={1}>
                <Divider />
              </Box>
        </Box>
        <Typography variant="h5"  align="center" gutterBottom>
           ${totalDebitAmount/100}
        </Typography>
    </Box>


    <Box display="flex" flexDirection="column" alignItems="center" bgcolor="white" mt={2} p={2} borderRadius={4} >
    <Grid container alignItems="center">
              <Grid item xs={8}>
                <Typography variant="body2" mt={1} sx={{ fontWeight: 'bold' }}>
                  Round-Ups {roundUpLabel}
                </Typography>
              </Grid>
              <Grid item xs={4} container justifyContent="flex-end">
                <FormControlLabel
                  control={<Switch  checked={roundUpsOn} onChange={handleRoundUpsToggle} color="main" sx={{
                    '& .Mui-checked': {
                      color: '#47b3b5', // Replace 'customColor' with your desired color
                    },
                    '& .Mui-checked + .MuiSwitch-track': {
                      backgroundColor: '#47b3b5', // Replace 'customColor' with your desired color
                    },
                  }}/>}
                />
              </Grid>
            </Grid>     
            <Collapse in={roundUpsOn}>  
      
     <Box display="flex" alignItems="center" width="100%" mt="10px">  
     <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">Round-Up to the nearest...</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={roundUpValue}
        onChange={handleRoundUpValueChange}
    >
        <FormControlLabel value={100} control={<StyledRadio icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />}  />}  label="$1" color="main"/>
        <FormControlLabel value={200} control={<StyledRadio icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />}  />}  label="$2" color="main"/>
        <FormControlLabel value={500} control={<StyledRadio icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />}  />}  label="$5" color="main"/>
        
      
      </RadioGroup>
    </FormControl>
     </Box>    
            </Collapse>
            
            
         <Grid container alignItems="center">
              <Grid item xs={8}>
                <Typography variant="body2" mt={1} sx={{ fontWeight: 'bold' }}>
                  Weekly Limit {weeklyLimitLabel}
                                  </Typography>
              </Grid>
              <Grid item xs={4} container justifyContent="flex-end">
                <FormControlLabel
                  control={<Switch checked={weeklyLimitOn} onChange={handleWeeklyLimitToggle} color="main" sx={{
                    '& .Mui-checked': {
                      color: '#47b3b5', // Replace 'customColor' with your desired color
                    },
                    '& .Mui-checked + .MuiSwitch-track': {
                      backgroundColor: '#47b3b5', // Replace 'customColor' with your desired color
                    },
                  }}/>}
                />
              </Grid>
            </Grid>
            <Collapse in={weeklyLimitOn}>      
            <Box display="flex" alignItems="center" width="100%" mt="10px">  
     <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">Limit round-up amount per week:</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={weeklyLimitValue}
        onChange={handleWeeklyLimitChange}
    >
        <FormControlLabel value={750} control={<StyledRadio icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />}  />}  label="$7.50" color="main"/>
        <FormControlLabel value={1000} control={<StyledRadio icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />}  />}  label="$10.00" color="main"/>
        <FormControlLabel value={1250} control={<StyledRadio icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />}  />}  label="$12.50" color="main"/>
        
      
      </RadioGroup>
    </FormControl>
     </Box>    
            </Collapse>
        
    </Box>



    <Box display="flex" flexDirection="column" alignItems="center" bgcolor="white" mt={2} p={2}  borderRadius={4} >
    <Grid container alignItems="center">
              <Grid item xs={8}>
                <Typography variant="body2" mt={1} sx={{ fontWeight: 'bold' }}>
                  Linked Savings Account {linkedSavingsLabel}
                </Typography>
              </Grid>
              <Grid item xs={4} container justifyContent="flex-end">
                <FormControlLabel
                  control={<Switch checked={linkedSavingsOn} onChange={handleLinkedSavingsToggle} color="main" sx={{
                    '& .Mui-checked': {
                      color: '#47b3b5', // Replace 'customColor' with your desired color
                    },
                    '& .Mui-checked + .MuiSwitch-track': {
                      backgroundColor: '#47b3b5', // Replace 'customColor' with your desired color
                    },
                  }}/>}
                />
              </Grid>
            </Grid>
            <Collapse in={linkedSavingsOn}>   

            <Box display="flex" alignItems="center" width="100%" mt='10px'>
                                  {SavingsAccountName}
                  </Box>

            <Box display="flex" alignItems="center" width="100%" mt='10px'>
                                  {savingsAccountValue}
                  </Box>
                  </Collapse>
            <Box display="flex" alignItems="center" width="100%" mt='10px'>                  
            <Button
            onClick={goToLinkedAccounts}
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: '150%' }}
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Manage Linked Account'}
         
          </Button>
          </Box>
            

            

     

    </Box>

    <Box display="flex" flexDirection="column" alignItems="center" bgcolor="white" mt={2} p={2} borderRadius={4} >
    <Grid container alignItems="center">
    <Box display="flex" justifyContent="center" alignItems="center" width="100%" mt='10px'>  
                <Typography variant="body2" mt={1}  sx={{ fontWeight: 'bold' }}>
                  One-Time Donation
                </Typography>  
                </Box>            
            </Grid>
   
            <Box display="flex" alignItems="center" width="100%" mt='10px'>

            <Button
            onClick={handleOpen}
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3, }}
           
          >
           Donate Now
         
          </Button>
          </Box>
          <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{fontWeight: 'bold'}}>
           One-Off Donation
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
          You can donate up to $250 in a one-off Donation
          </Typography>
          <form onSubmit={formik.handleSubmit}>
          <TextField
              fullWidth
              id="amount"
              name="amount"
              label="Amount"
              value={formik.values.amount}
              onChange={formik.handleChange}
              error={formik.touched.amount && Boolean(formik.errors.amount)}
              helperText={formik.touched.amount && formik.errors.amount}
              required
              variant="outlined"
              sx={{ '& .MuiOutlinedInput-root': {borderRadius: '16px',  height: '45px', mt: '3px', mb: '3px'}, }}            />
           <Box display="flex" width="100%" justifyContent="space-between">
                   </Box>
          <Box display="flex" width="100%" justifyContent="space-between">
           <Box display="flex"  width="49%" >
           <Button onClick={handleClose}
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: '100%' }}
             >
            <Typography >
           Cancel
            </Typography>
         
          </Button>

           </Box>
           <Box display="flex"  width="49%" >
           <Button
            type="submit"
            fullWidth
            variant="contained"
            color="switchColor"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: '100%' }}
            disabled={isLoadingDonation}
          >
            <Typography >
            {isLoadingDonation ? 'Loading...' : 'Submit'}
            </Typography>
         
          </Button>

           </Box>
           </Box>

          </form>

        </Box>
        </Modal>

         
    </Box>


    <Box display="flex" flexDirection="column" alignItems="center" bgcolor="white" mt={2} p={2}  borderRadius={4} >
    <Grid container alignItems="center">
    <Box display="flex"  alignItems="center" width="100%" mt='10px'>  
    <Grid item xs={8}>
                <Typography variant="body2" mt={1} sx={{ fontWeight: 'bold' }}>
                  Donation History
                </Typography>
              </Grid>
              <Grid item xs={4} container justifyContent="flex-end">
              <Typography variant="body2" mt={1} >
              <Link href="/history" underline="always" color="inherit">   View All</Link>
                </Typography>
                </Grid>
                </Box>            
            </Grid>
   
            <Box display="flex" alignItems="center" width="100%" mt='10px'>

           
          </Box>

         
    </Box>
    <Grid item xs={12} justifyContent="center" height={30}>
    </Grid>
    </Container>
            </CssBaseline>
            </ThemeProvider>
       
  );

}
export default Dashboard;

export {};