// src/components/screens/ClubList.tsx
import React, { useState, useEffect } from 'react';
import { TextField, List, ListItem, ListItemText, Container, Typography, Box, Button, Grid, Link, Stepper, Step, StepLabel } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import Logo from '../../../assets/images/logo.png';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

const theme = createTheme({
    palette: {
      primary: {
        main: '#000000',
      },
      background: {
        default: '#47b3b5', // Set your desired background colour here
      },
      appbar: {
        default: '#ffffff', // Set your desired background colour here
      },
      switchColor: {
        main: '#47b3b5',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: '#ffffff', // Ensure the body has the same background colour
          },
          secondary: {
            backgroundColor: '#47b3b5', // Ensure the body has the same background colour
          },
          switch: {
            main: '#47b3b5',
          }
        },
      },
    },
  });

  const steps = [
    'Register Account',
    'Connect To Bank',
    'Select Round-Up Accounts',
    'Select Funding Account'
  ];

const LaunchConsent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [consentLink, setConsentLink] = useState('');
  const cdrid = sessionStorage.getItem('cdrid');

  const fetchConsent = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post('https://minerva.sipora.io/api/client/round-up/user/consent',
      {cdrid: cdrid},
      { headers: {'Content-Type': 'application/json'} }); // Replace with your actual API endpoint
      const consentLink = response.data.consentLink;
      window.location.href = consentLink // Adjust based on your API response structure
    } catch (error) {
      console.error('Error creating consent Link:', error);
    } finally {
      setIsLoading(false)
    }
  };

  return (
    <Container component="main" maxWidth="xs">
 
      <Box display="flex" flexDirection="column" alignItems="center" mt={4} p={2} >
      <Grid container justifyContent="center" mb={2}>
  
      {/*}
      <Grid item xs={12} justifyContent="center">
      <Box display="flex" justifyContent="center" mb={4}>
      <img src={Logo} alt="Logo"  style={{ width: '70%', height: 'auto'}} />
      </Box></Grid> */}
           <Box display="flex" flexDirection="column" alignItems="center" mt={4} mb={6} style={{ width: '100%'}} >
        <Stepper activeStep={1} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
        </Box>
      
        <Typography variant="h4" component="h2" align="center" mb={2} sx={{fontWeight: 'bold'}} gutterBottom>
        Welcome
      </Typography>
      
      <Box display="flex" flexDirection="column" alignItems="left" mb={2} style={{ width: '100%'}}>
      <Typography variant="body2" component="h2" align="left" mb={2} gutterBottom>
        We will guide you through the last few steps to finish setting up your accounts!
      </Typography>
      <Typography variant="body2" component="h2" align="left"  gutterBottom>
      You'll be asked to connect to your bank using your internet banking credentials, select a round-up amount and the accounts you'd like to round up transactions from, then finally select a single funding account that we'll debit your total from every Monday, Wednesday and Friday.
      </Typography>
   
      </Box>
       
      </Grid>
      <ThemeProvider theme={theme}>
   
          <Button
            onClick={fetchConsent}
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: "100%"  }}
            disabled={isLoading}

                  
          >
            {isLoading ? 'Loading...' : 'Continue'}
         
          </Button>
 </ThemeProvider>


                    
          <Box mt={2} display="flex" justifyContent="center">
            <Link href="#" variant="body2" sx={{ mr: 3}}>
              Help
            </Link>
            <Link href="#" variant="body2" >
              Terms
            </Link>
            <Link href="#" variant="body2" sx={{ ml: 3}}>
              Privacy
            </Link>
          </Box>
        </Box>
    </Container>
  );
};

export default LaunchConsent;

export {};