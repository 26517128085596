import React, { useState, useEffect } from 'react';
import { TextField, List, ListItem, ListItemText, Container, Typography, Box, Button, Grid, Link, Stepper, Step, StepLabel } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import Logo from '../../../assets/images/logo.png';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';

const theme = createTheme({
    palette: {
      primary: {
        main: '#000000',
      },
      background: {
        default: '#47b3b5', // Set your desired background colour here
      },
      appbar: {
        default: '#ffffff', // Set your desired background colour here
      },
      switchColor: {
        main: '#47b3b5',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: '#ffffff', // Ensure the body has the same background colour
          },
          secondary: {
            backgroundColor: '#47b3b5', // Ensure the body has the same background colour
          },
          switch: {
            main: '#47b3b5',
          }
        },
      },
    },
  });

  

  const steps = [
    'Register Account',
    'Connect To Bank',
    'Select Round-Up Accounts',
    'Select Funding Account'
  ];

const Consented = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [statusMessage, setStatusMessage] = useState('Checking your bank connection and authorising accounts');
  const [pageTitle, setPageTitle] = useState('Connecting...');
  const location = useLocation();
  const cdrid = sessionStorage.getItem('cdrid');
  const [stepperStep, setStepperStep] = useState(1);

  useEffect(() => {
    const fetchJob = async () => {
      const searchParams = new URLSearchParams(location.search);
      const jobId = searchParams.get('jobId');
      localStorage.setItem('jobId', jobId);

  
  
      if (!jobId) {
        // If jobId is not present in the URL, update the status message and don't navigate
        setPageTitle('Uh Oh!');
        setStatusMessage('We having some trouble connecting to your bank, please try again later or contact supportfor assistance');
        return; // Stop further execution
      }
     
     
      try {
        const response = await axios.post(
          'https://minerva.sipora.io/api/client/round-up/user/check-job',
          { jobId: jobId, cdrid: cdrid },
          { headers: { 'Content-Type': 'application/json' } }
        );
        const status = response.status;
        if (status === 200) {
          setStepperStep(2);
          setPageTitle('Success!')
          setStatusMessage('successfully connected - we are now retrieving your accounts.')
          const retrieveAccounts = await axios.post(
            'https://minerva.sipora.io/api/client/round-up/user/retrieve-accounts',
            { cdrid: cdrid },
            { headers: { 'Content-Type': 'application/json' } }
          );
          if (status === 200) {
            setStatusMessage('accounts have succesfully been retrieved');
            navigate('/roundup-accounts');
          }
        } else if (status === 202) {
          setTimeout(() => fetchJob(jobId), 2000);
        }
      } catch (error) {
        setPageTitle('Uh Oh!')
        setStatusMessage('Failed to check in with your bank. Please try again.');
      }
    };

    fetchJob();
  }, [location.search, cdrid]);



  return (
    <Container component="main" maxWidth="xs">
 
      <Box display="flex" flexDirection="column" alignItems="center" mt={4} p={2} >
      <Grid container justifyContent="center" mb={2}>
      {/*}
      <Grid item xs={12} justifyContent="center">
      <Box display="flex" justifyContent="center" mb={4}>
      <img src={Logo} alt="Logo"  style={{ width: '70%', height: 'auto'}} />
  </Box></Grid> */}
         <Box display="flex" flexDirection="column" alignItems="center" mt={4} mb={6} style={{ width: '100%'}} >
        <Stepper activeStep={stepperStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
        </Box>
      
      <Typography variant="h4" component="h2" align="center" mt={2} sx={{fontWeight: 'bold'}} gutterBottom>
      {pageTitle}
      </Typography>
      
      <Box display="flex" flexDirection="column" alignItems="center" mt={4} mb={16} style={{ width: '100%'}}>
      <Typography variant="body2" component="h2" align="center"  gutterBottom>
       {statusMessage}
      </Typography>
   
      </Box>

      
    
      </Grid>
      



                    
          <Box mt={2} display="flex" justifyContent="center">
            <Link href="#" variant="body2" sx={{ mr: 3}}>
              Help
            </Link>
            <Link href="#" variant="body2" >
              Terms
            </Link>
            <Link href="#" variant="body2" sx={{ ml: 3}}>
              Privacy
            </Link>
          </Box>
        </Box>
    </Container>
  );
};

export default Consented;

export {};