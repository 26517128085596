// src/components/DrawerMenu.tsx
import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import { Link as RouterLink } from 'react-router-dom';

const DrawerMenu = () => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }

    setOpen(open);
  };

  const menuItems = [
    { text: 'Dashboard', icon: <HomeOutlinedIcon />, link: '/dashboard' },
    { text: 'Linked Accounts', icon: <AccountBalanceWalletOutlinedIcon />, link: '/linked-accounts' },
    { text: 'Privacy / T&Cs', icon: <VerifiedUserOutlinedIcon />, link: '/legal' },
    { text: 'Support', icon: <ContactSupportOutlinedIcon />, link: '/support' },
    { text: 'Profile', icon: <SettingsOutlinedIcon />, link: '/profile' },
    { text: 'Logout', icon: <LogoutOutlinedIcon />, link: '/logout' },
    // Add more items here
  ];

  return (
    <>
      <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
        <MenuIcon />
        <Typography sx={{ml: '3px'}}>
     
        </Typography>
      </IconButton>
      <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
        <div
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List sx={{mt: '50px', mr: '15px'}}>
            {menuItems.map((item, index) => (
              <ListItem button key={index} component={RouterLink} to={item.link}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
     
          {/* Add more items or sections here if needed */}
        </div>
      </Drawer>
    </>
  );
};

export default DrawerMenu;

export {};