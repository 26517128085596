import React, { useEffect, useState } from 'react';
import { Container, TextField, Button, Checkbox, FormControlLabel, Link, Typography, Grid, Box} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Logo from '../../../assets/images/logo.png';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import { auth } from '../../../firebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';


const theme = createTheme({
    palette: {
        primary: {
        main: '#000000',
    },
      },
  });

const LoginPage = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [sourceClub, setSourceClub] =  useState<string>('');
    const navigate = useNavigate();
    const { variable } = useParams<{ variable: string }>();

    const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const signIn = await signInWithEmailAndPassword(auth, email, password);
      sessionStorage.setItem('userId', signIn.user.uid)
      navigate('/dashboard'); // Navigate to the clubs page upon successful login
    } catch (error) {
      setError('Failed to log in. Please check your credentials.');
      setMessage('Failed to log in. Please check your credentials.');
    } finally {
      setIsLoading(false);
    }
  };

  

  useEffect(() => {
    if (variable) {
    const sourceClubCode = variable!;
    localStorage.setItem('sourceClub',sourceClubCode) }

  },[variable ])
  

  return (
    <Container component="main" maxWidth="xs">
        <Box display="flex" flexDirection="column" alignItems="center" mt={8} p={2} >
      <Grid container justifyContent="center" mb={2}>
    
      <Grid item xs={12} justifyContent="center">
      <Box display="flex" justifyContent="center">
      <img src={Logo} alt="Logo"  style={{ width: '90%', height: 'auto'}} />
      </Box></Grid></Grid>
          
       
     
        <form noValidate onSubmit={handleLogin}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ '& .MuiOutlinedInput-root': {borderRadius: '16px',  height: '45px', mt: '3px', mb: '3px'}, }}
            
          />
          <TextField
            variant="outlined"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ '& .MuiOutlinedInput-root': {borderRadius: '16px',  height: '45px', mt: '3px', mb: '3px'}, }}
            InputProps={{
              endAdornment: (
                <ThemeProvider theme={theme}>  <Button onClick={handleClickShowPassword}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </Button></ThemeProvider>
              ),
            }}
          />

<Grid item xs={12} justifyContent="left">
      <Box display="flex" justifyContent="left">
      <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
      </Box></Grid>
          
           <ThemeProvider theme={theme}>
           <Box display="flex" justifyContent="center" sx={{mt: '10px', color: 'red'}}> {message}</Box> 
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3  }}
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Login'}
         
          </Button></ThemeProvider>
          <Grid item xs={12} justifyContent="center">
          <Box display="flex" justifyContent="center" p={1}>
          <Typography variant="body2"> Don't have an account? <Link component={RouterLink} to="/clubs" variant="body2" sx={{ ml: 0.5, textDecoration: 'none' }}> Register
            </Link>
            </Typography></Box>
            </Grid>
             <Grid item xs={12} justifyContent="center">
            <Box display="flex" justifyContent="center" p={1}>
            <Typography variant="body2">
                Forgot your password? 
              <Link href="#" variant="body2" sx={{ ml: 0.5, textDecoration: 'none' }}> Reset it
            </Link>
            </Typography></Box></Grid>
         
          <Box mt={2} display="flex" justifyContent="center">
            <Link href="#" variant="body2" sx={{ mr: 3}}>
              Help
            </Link>
            <Link href="#" variant="body2" >
              Terms
            </Link>
            <Link href="#" variant="body2" sx={{ ml: 3}}>
              Privacy
            </Link>
          </Box>
        </form>
        </Box>
    </Container>
  );
};

export default LoginPage;

export {};